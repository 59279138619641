(function (win, $) {
    'use strict';

    var dk = win.dk,
        // Links matching the following selectors will be excluded
        excludedLinkSelectors = [
            '.eld',
            '.no-exit-link',
            '.expand'
        ].join(','),
        // Links with parent elements matching the following selectors will be excluded
        excludedParentSelectors = [
            '.no-exit-link',
            '.addthis_toolbox',
            '.recaptchatable',
            '.twitter-tweet',
            '.embed'
        ].join(','),
        // Links matching the following selectors will still be treated as exit links but won't
        // display the disclaimer icon
        noIconLinkSelectors = [
            '.no-disclaimer',
            '.cta',
            '.dk-cta',
            '.dk-box > a:first-child',
            '.dk-box.border-thick[class*=accent-] h2 a'
        ].join(','),
        noIconParentSelectors = [
            '.no-disclaimer',
            '.dk-card',
            '.dk-card-row',
            '.sc-card',
            '.dk-suite-nav',
            '.previous-next',
            '.contact-buttons',
            '.dk-share-buttons',
            '.dk-tabnav',
            '.social-contact-links',
            '.rb-buttons .buttons'
        ].join(','),
        // Links with hostnames that end with the following domains will be treated as internal org links
        internalDomains = [
            $('<a/>').attr('href', location.href).prop('hostname'), // Current host
            'obesityresearch.nih.gov',
            'www.obesityresearch.nih.gov'
        ].join('|'),
        // Links with hostnames that end with the following domains will be treated as NIH links
        nihDomains = [
            'cancer.gov',
            'clinicaltrials.gov',
            'drugabuse.gov',
            'genome.gov',
            'medlineplus.gov',
            'nih.gov',
            'nih.sharepoint.com'
        ].join('|'),
        // Determines whether to render the NIH-specific exit link icon
        showNihIcon = false,
        iconText = 'External link',
        iconTitle = 'View the external link disclaimer',
        nihIconText = 'NIH external link',
        nihIconTitle = nihIconText,
        internalHostRegex = new RegExp('(' + internalDomains.replace('.', '\\.') + ')$', 'i'),
        nihHostRegex = new RegExp('(' + nihDomains.replace('.', '\\.') + ')$', 'i');

    function isString(str) {
        return typeof str === 'string';
    }

    function startsWith(str, val) {
        return isString(str) && isString(val) && str.slice(0, val.length) === val;
    }

    function isInternalLink($link) {
        var hn = isString($link) ? $link : $link.prop('hostname') || '';
        return hn.match(internalHostRegex) ? true : false;
    }

    function isNihLink($link) {
        var hn = isString($link) ? $link : $link.prop('hostname') || '';
        return hn.match(nihHostRegex) ? true : false;
    }

    function evaluateExitLinks(parent) {
        if (dk.isEditing) return;

        // Identify links to process the handle each of them
        $(parent || 'main').find('a[href]').filter(function () {
            var $me = $(this), proto = $me.prop('protocol');
            return !startsWith(proto, 'javascript') &&
                !startsWith(proto, 'mailto') &&
                !startsWith(proto, 'tel') &&
                !$me.is(excludedLinkSelectors) &&
                !$me.parents().is(excludedParentSelectors) &&
                !isInternalLink($me);
        }).each(function () {
            var $me = $(this),
                $icon = null,
                isNih = isNihLink($me),
                useNbsp = $me.hasClass('exit-no-break');
            $me.addClass('exit-link');
            if (!isNih) {
                $me.attr({target: '_blank', rel: 'noopener'});
            }
            if (!$me.is(noIconLinkSelectors) && !$me.parents().is(noIconParentSelectors) && !$me.next().is('.eld')) {
                if (isNih && showNihIcon) {
                    $icon = $('<i/>').addClass('eld i-exit-nih').text(nihIconText);
                    if (nihIconTitle) $icon.attr('title', nihIconTitle);
                }
                if (!isNih) {
                    $icon = $('<a/>').attr('href', dk.pages.disclaimers).addClass('eld i-exit').text(iconText);
                    if (iconTitle) $icon.attr('title', iconTitle);
                }
                if ($icon) $me.after(useNbsp ? '&nbsp;' : ' ', $icon);
            }
        });
    }

    dk.evaluateExitLinks = evaluateExitLinks;

    // Document ready
    if (!dk.isEditing) $(function () { evaluateExitLinks(); });
})(window, window.jQuery);
